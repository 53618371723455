<template>
    <div>
        <div @click="is_menu_show = false"
             :style="`position: fixed; height: 100vh; width: 100vw; background: ${is_menu_show ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,0)'}; z-index: ${is_menu_show ? 999 : -1}; transition: ease all .2s `"></div>
        <v-app>
            <v-app-bar style="background: var(--v-bg-base); z-index: 999">
                <custom-title></custom-title>
                <v-spacer></v-spacer>
                <div style="margin-right: 20px">
                    <i @click="set_lang('zh')" v-if="$i18n.locale==='en'" style="font-size: 20px; cursor: pointer;"
                       class="airport icon-zhongwen text_color--text"></i>
                    <i @click="set_lang('en')" v-else style="font-size: 20px; cursor: pointer;"
                       class="airport icon-yingwen text_color--text"></i>
                </div>
                <div style="margin-right: 20px">
                    <i @click="set_dark_mode('0')" v-if="$store.state.is_dark"
                       style="font-size: 22px; cursor: pointer;" class="airport icon-taiyang text_color--text"></i>
                    <i @click="set_dark_mode('1')" v-else style="font-size: 22px; cursor: pointer;"
                       class="airport icon-yueliang1 text_color--text"></i>
                </div>
                <v-menu
                        bottom
                        offset-y
                        open-on-hover
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn fab text v-on="on" v-bind="attrs">
                            <v-badge
                                    bordered
                                    v-bind="attrs"
                                    v-on="on"
                                    bottom
                                    color="green"
                                    dot
                                    offset-x="10"
                                    offset-y="10"
                            >
                                <v-avatar>
                                    <img
                                            :src=" $store.state.user_info.userinfo.avatar || 'https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/assets/avatar.jpeg'"
                                            alt="adminUser"
                                    >
                                </v-avatar>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-list color="secondary">
                        <v-list-item :to="{ name: 'editPersonalInfo'}">
                            <v-list-item-title>{{$t('airport.my')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="open_edit_password">
                            <v-list-item-title>{{ $t('airport.change_password') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: 'login'}">
                            <v-list-item-title>{{ $t('airport.log_out') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-dialog width="800px" v-model="edit_password_dialog">
                <EditPassword @close_dialog="close_dialog" ref="edit_password"></EditPassword>
            </v-dialog>


            <div style="width: 100%; height: calc(100vh - 64px); overflow: hidden; position: relative">

                <div @click="is_menu_show = true" :class="{menu_box_show: is_menu_show, menu_box_hide: !is_menu_show}"
                     :style="`z-index: ${is_menu_show ? 9999 : 1}; overflow: hidden`">
                    <i v-if="!is_menu_show" class="airport icon-menu"
                       style="position: absolute; right:130px; top:20px; z-index: 1; font-size: 38px; transform: rotate(45deg);"></i>
                    <div v-if="is_menu_show" class="d-flex flex-wrap ">
                        <v-icon @click.stop="is_menu_show = false" style="position: absolute; right: 5px; top: 5px">
                            mdi-close
                        </v-icon>
                        <div @click="to_link('flightInformation')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-feihangqingbaoqu menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.flight_information_area') }}
                            </div>
                        </div>
                        <!--                      :style="$i18n.locale==='zh' ? '' : 'height:auto !important'"-->
                        <div @click="to_link('air_route')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-lujingyouhua menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.approach_and_departure_route_optimization') }}
                            </div>
                        </div>
                        <div @click="to_link('flight_conflict')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-chongtujiance menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.route_conflict_detection') }}
                            </div>
                        </div>
                        <div @click="to_link('electromagnetic')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-icon_cichang_max menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.visualization') }}
                            </div>
                        </div>

                        <!--                        <div @click="to_link('aircraftList')" class="menu pa-4 mr-4 mb-4">-->
                        <!--                            <i class="airport icon-jiaotongbaozhangguanli menu__icon"></i>-->
                        <!--                            <div>-->
                        <!--                                {{ $t('airport.aircraft_list') }}-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                        <div @click="to_link('freeRouteIndex')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-aaa menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.freeways') }}
                            </div>
                        </div>
                        <div @click="to_link('aircraftList')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-hangbandongtai menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.aircraft_list') }}
                            </div>
                        </div>
                        <div @click="to_link('user_list')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-yonghuguanli menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.user_management') }}
                            </div>
                        </div>
                        <div @click="to_link('role_permissions')" class="menu pa-4 mr-4 mb-4">
                            <i class="airport icon-jiaoseguanli menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.role_management') }}
                            </div>
                        </div>
                        <div @click="to_link('log_management')" class="menu pa-4 mr-4 mb-4">
                            <!--                            <v-icon size="38" class="airport menu__icon">mdi-text-box-outline</v-icon>-->
                            <i class="airport icon-wj-rz menu__icon"></i>
                            <div class="hidden">
                                {{ $t('airport.log_management') }}
                            </div>
                        </div>
                    </div>
                </div>


                <div id="Map"></div>
                <div class="search__box">
                    <v-text-field
                            outlined
                            style="max-width: 400px; margin-top: 25px"
                            @keydown.enter="doGet(1)"
                            :label="$t('airport.search')"
                            v-model="search_info.name"
                    ></v-text-field>
                </div>
                <div class="right_airport_list">
                    <div @click="click_airport_event(index)"
                         style="cursor:pointer; box-shadow: 0 0 10px var(--v-shadow_color-base)"
                         v-for="(item, index) of desserts" cols="3" lg="3" md="6" xl="3" sm="12">
                        <v-card height="150px" class="pa-4 pt-0 pl-0 pr-0 airport__item"
                                :style="index !== select_air_port ? `user-select: none; background: var(--v-list-base)` : `user-select: none; background: rgb(91, 163, 235)`">
                            <i class="airport airport-icon icon-jichangjiesong"></i>
                            <v-container class="text-caption">
                                <v-row>
                                    <v-col cols="12"
                                           :style="select_air_port != index ? 'font-size: 15px; color: #5BA3EB' : 'font-size: 15px; color: #F5F5F5'"
                                           class="font-weight-bold">
                                        {{item.name}}
                                    </v-col>
                                    <v-col cols="12">
                                        {{ $t('airport.area') }}: <span class="font-number" style="font-size: 18px">{{item.area}}</span>
                                        ㎡, {{ $t('airport.tarmac') }}:
                                        <span class="font-number" style="font-size: 18px">{{item.apron_number}}</span> ,
                                        {{ $t('airport.runway') }}: <span class="font-number" style="font-size: 18px">{{item.runway_number}}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <div style="position: absolute; bottom: 0px; width: 100%;padding: 10px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs"
                                               color="secondary"
                                               text
                                               v-on="on" @click="has_airport_id(item.id)" depressed x-small fab>
                                            <i class="airport icon-node small-font text-center"
                                               :style="select_air_port != index ? 'font-size: 18px !important; color: var(--v-btn_text-base)' : 'font-size: 18px !important; color: #fff'"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('airport.Node')}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs"
                                               color="secondary"
                                               text
                                               v-on="on" @click="openEdge(item.id)" class="ml-2" depressed x-small fab>
                                            <i class="airport icon-edge1 small-font text-center"
                                               :style="select_air_port != index ? 'font-size: 18px !important; color: var(--v-btn_text-base)' : 'font-size: 18px !important; color: #fff'"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('airport.Edge')}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs"
                                               color="secondary"
                                               text
                                               v-on="on" @click.stop="open_preview_airport(item)" class="ml-2" depressed
                                               x-small fab>
                                            <i class="airport icon-preview small-font text-center"
                                               :style="select_air_port != index ? 'font-size: 18px !important; color: var(--v-btn_text-base)' : 'font-size: 18px !important; color: #fff'"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('preview')}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs"
                                               color="secondary"
                                               text
                                               v-on="on" @click="toEdit(item)" class="ml-2" depressed x-small fab>
                                            <i class="airport small-font icon-bianji text-center"
                                               :style="select_air_port != index ? 'font-size: 18px !important; color: var(--v-btn_text-base)' : 'font-size: 18px !important; color: #fff'"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('edit')}}</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs"
                                               color="secondary"
                                               text
                                               @click="set_air_port_id(item.id)"
                                               v-on="on"
                                               class="ml-2" depressed x-small fab>
                                            <i class="airport small-font icon-enterinto text-center"
                                               :style="select_air_port != index ? 'font-size: 18px !important; color: var(--v-btn_text-base)' : 'font-size: 18px !important; color: #fff'"></i>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('airport.Enter')}}</span>
                                </v-tooltip>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>


            <v-btn
                    fab
                    color="accent"
                    class="float-btn"
                    :to="{ name: 'airport-add' }"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog
                    v-model="dialog"
                    width="800px"
            >
                <v-card color="secondary">
                    <v-tabs
                            v-model="tab"
                            background-color="transparent"
                            color="basil"
                            grow
                    >
                        <v-tab
                                v-for="item in items"
                                :key="item.text"
                                @change="changeTab"
                        >
                            {{ item.text }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <!--                        <v-tab-item-->
                        <!--                        >-->
                        <!--                            <v-card color="secondary"  flat>-->
                        <!--                                <v-card-text>-->
                        <!--                                    <v-container class="pd-30">-->
                        <!--                                        <v-form ref="form" v-model="valid" lazy-validation>-->
                        <!--                                            <v-row>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.startDate"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.startDate"-->
                        <!--                                                                    :label="$t('airport.start_date')"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    :rules="[(v) => !!v || 'startDate is required']"-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-date-picker-->
                        <!--                                                                v-model="selectTime.startDate"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu.save(selectTime.startDate)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-date-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu1"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.startTime"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.startTime"-->
                        <!--                                                                    :label="$t('airport.start_time')"-->
                        <!--                                                                    :rules="[(v) => !!v || 'startTime is required']"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-time-picker-->
                        <!--                                                                v-model="selectTime.startTime"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu1.save(selectTime.startTime)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-time-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu2"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.endDate"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.endDate"-->
                        <!--                                                                    :label="$t('airport.end_date')"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    :rules="[(v) => !!v || 'endDate is required']"-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-date-picker-->
                        <!--                                                                v-model="selectTime.endDate"-->
                        <!--                                                                :min="selectTime.startDate"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu2.save(selectTime.endDate)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-date-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu3"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.endTime"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.endTime"-->
                        <!--                                                                    :label="$t('airport.end_time')"-->
                        <!--                                                                    :rules="[(v) => !!v || 'endTime is required']"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                                    required-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-time-picker :min="selectTime.startDate == selectTime.endDate? change(): null"-->
                        <!--                                                                v-model="selectTime.endTime"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu3.save(selectTime.endTime)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-time-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                            </v-row>-->
                        <!--                                        </v-form>-->
                        <!--                                    </v-container>-->
                        <!--                                </v-card-text>-->
                        <!--                            </v-card>-->
                        <!--                        </v-tab-item>-->
                        <!--                        <v-tab-item>-->
                        <!--                            <v-card color="secondary"  flat>-->
                        <!--                                <v-card-text>-->
                        <!--                                    <v-container class="pd-30">-->
                        <!--                                        <v-form ref="form1" v-model="valid" lazy-validation>-->
                        <!--                                            <v-row>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu4"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.startDate"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.startDate"-->
                        <!--                                                                    :label="$t('airport.start_date')"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    :rules="[(v) => !!v || 'startDate is required']"-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-date-picker-->
                        <!--                                                                v-model="selectTime.startDate"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu4.save(selectTime.startDate)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-date-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu5"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.startTime"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.startTime"-->
                        <!--                                                                    :label="$t('airport.start_time')"-->
                        <!--                                                                    :rules="[(v) => !!v || 'startTime is required']"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-time-picker-->
                        <!--                                                                v-model="selectTime.startTime"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu5.save(selectTime.startTime)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-time-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu6"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.endDate"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.endDate"-->
                        <!--                                                                    :label="$t('airport.end_date')"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    required-->
                        <!--                                                                    :rules="[(v) => !!v || 'endDate is required']"-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-date-picker-->
                        <!--                                                                v-model="selectTime.endDate"-->
                        <!--                                                                :min="selectTime.startDate"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu6.save(selectTime.endDate)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-date-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <v-col cols="12" sm="6" md="6">-->
                        <!--                                                    <v-menu-->
                        <!--                                                            ref="menu7"-->
                        <!--                                                            :close-on-content-click="false"-->
                        <!--                                                            :return-value.sync="selectTime.endTime"-->
                        <!--                                                            transition="scale-transition"-->
                        <!--                                                            offset-y-->
                        <!--                                                            min-width="auto"-->
                        <!--                                                    >-->
                        <!--                                                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                                                            <v-text-field-->
                        <!--                                                                    v-model="selectTime.endTime"-->
                        <!--                                                                    :label="$t('airport.end_time')"-->
                        <!--                                                                    :rules="[(v) => !!v || 'endTime is required']"-->
                        <!--                                                                    readonly-->
                        <!--                                                                    v-bind="attrs"-->
                        <!--                                                                    v-on="on"-->
                        <!--                                                                    required-->
                        <!--                                                            ></v-text-field>-->
                        <!--                                                        </template>-->
                        <!--                                                        <v-time-picker-->

                        <!--                                                                :min="-->
                        <!--                            selectTime.startDate == selectTime.endDate-->
                        <!--                              ? change() : null-->
                        <!--                          "-->
                        <!--                                                                v-model="selectTime.endTime"-->
                        <!--                                                                scrollable-->
                        <!--                                                        >-->
                        <!--                                                            <v-spacer></v-spacer>-->
                        <!--                                                            <v-btn-->
                        <!--                                                                    text-->
                        <!--                                                                    color="primary"-->
                        <!--                                                                    @click="$refs.menu7.save(selectTime.endTime)"-->
                        <!--                                                            >{{$t('confirm')}}-->
                        <!--                                                            </v-btn-->
                        <!--                                                            >-->
                        <!--                                                        </v-time-picker>-->
                        <!--                                                    </v-menu>-->
                        <!--                                                </v-col>-->
                        <!--                                            </v-row>-->
                        <!--                                        </v-form>-->
                        <!--                                    </v-container>-->
                        <!--                                </v-card-text>-->
                        <!--                            </v-card>-->
                        <!--                        </v-tab-item>-->
                        <v-tab-item>
                            <v-card color="secondary" flat>
                                <v-card-text>
                                    <v-container class="pd-30">
                                        <v-row>
                                            <v-col :cols="6">
                                                <v-datetime-picker :label="$t('airport.start_time')" time-format="HH:mm"
                                                                   v-model="startTime">

                                                </v-datetime-picker>
                                            </v-col>
                                            <v-col :cols="6">
                                                <v-datetime-picker :label="$t('airport.end_time')" time-format="HH:mm"
                                                                   v-model="endTime">

                                                </v-datetime-picker>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card color="secondary" flat>
                                <v-card-text>
                                    <v-container class="pd-30">
                                        <v-row>
                                            <v-col :cols="6">
                                                <v-datetime-picker :label="$t('airport.start_time')" time-format="HH:mm"
                                                                   v-model="startTime">

                                                </v-datetime-picker>
                                            </v-col>
                                            <v-col :cols="6">
                                                <v-datetime-picker :label="$t('airport.end_time')" time-format="HH:mm"
                                                                   v-model="endTime">

                                                </v-datetime-picker>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" :disabled="!valid" small @click="validate">
                            {{$t('confirm')}}
                        </v-btn
                        >
                        <v-btn color="secondary2" small @click="dialog = false"
                        > {{$t('cancel')}}
                        </v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="node_dialog" width="800px">
                <v-card color="secondary">
                    <v-card-title>
                        {{$t('airport.node_data')}}
                    </v-card-title>
                    <v-container class="pd-20">
                        <v-row class="pa-3">
                            <v-col cols="8">
                                <v-select
                                        v-model="selectNode"
                                        :items="node"
                                        :label="$t('airport.selectNode')"
                                        return-object
                                        single-line
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="submitNode" color="accent">{{$t('confirm')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="edge_dialog" width="800px">
                <v-card>
                    <v-card-title>
                        {{$t('airport.edge')}}
                    </v-card-title>
                    <v-container class="pd-20">
                        <v-row class="pa-3">
                            <v-col cols="8">
                                <v-select
                                        v-model="selectEdge"
                                        :items="edge"
                                        :label="$t('airport.selectNode')"
                                        return-object
                                        single-line
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="submitEdge" color="primary">{{$t('confirm')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog width="1280px" v-model="menu_dialog">
                <v-card color="secondary" class="pa-4 pr-0 pb-0 d-flex flex-wrap">
                    <div @click="to_link('flightList')" class="menu pa-4 mr-4 mb-4">
                        <i class="airport icon-hangbandongtai menu__icon"></i>
                        <div>
                            {{ $t('airport.flight_management') }}
                        </div>
                    </div>
                    <div @click="to_link('AirportSecurityScheduler')" class="menu pa-4 mr-4 mb-4">
                        <i class="airport icon-jiaotongbaozhangguanli menu__icon"></i>
                        <div>
                            {{ $t('airport.airport_security') }}
                        </div>
                    </div>
                    <div @click="to_link('flightPreview')" class="menu pa-4 mr-4 mb-4">
                        <i class="airport icon-gantetu menu__icon"></i>
                        <div>
                            {{ $t('airport.flight_gantt') }}
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
    let moment = require('moment')
    import { get_my_info } from '@/api/my'
    import {get_list, del_info} from "../../../api/airport";
    // import Setting from '@/layout/components/Settings'
    import EditPassword from '@/pages/auth/editpassword'
    import CustomTitle from "../../../components/CustomTitle";

    export default {
        data() {
            return {
                valid: true,
                dialog: false,
                node_dialog: false,
                totalDesserts: 0,
                desserts: [],
                menu_dialog: false,
                edit_password_dialog: false,
                loading: true,
                dialog_airport_id: '',
                options: {},
                dates: ["", ""],
                select_air_port: 0,
                addendTime: "",
                markers: [],
                user_id:null,
                is_menu_show: false,
                selectTime: {
                    startDate: "",
                    startTime: "",
                    endData: "",
                    endTime: "",
                },
                search_info: {
                    pageindex: 1,
                    pagesize: 10,
                    name: "",
                },
                item: [
                    {text: '机场节点', value: 0},
                    {text: '机场保障模块节点', value: 1}],
                selectNode: {"text": "机场节点", "value": 0},
                selectEdge: {
                    value: 0
                },
                airport_id: null,
                list_count: 0,
                tab: null,
                // items: ['预览机场', '预览保障车'],
                isAirport: true,

                edge_dialog: false,

                startTime: '',
                endTime: '',
            };
        },
        methods: {
            close_dialog() {
                this.edit_password_dialog = false
            },
            open_edit_password() {
                this.edit_password_dialog = true
                this.$nextTick(() => {
                    this.$refs.edit_password.reset()
                })
            },
            set_lang(value) {
                localStorage.setItem('lang', value)
                this.$i18n.locale = value
            },
            set_dark_mode(value) {
                this.$set_dark(value)
            },
            set_air_port_id(air_port_id) {
                this.is_menu_show = false
                this.$store.commit('set_air_port_id', air_port_id)
                this.menu_dialog = true
            },
            to_link(name) {
                this.$router.push({
                    name,
                    query: {airport_id: this.$store.state.select_air_port_id}
                })
            },
            click_airport_event(index) {
                for (let i of this.markers) {
                    i.setAnimation(null)
                    i.setOpacity(0.5)
                }
                if (this.markers.length > index) {
                    this.map.panTo({lat: this.markers[index].position.lat(), lng: this.markers[index].position.lng()})
                    this.markers[index].setAnimation(google.maps.Animation.BOUNCE)
                    this.markers[index].setOpacity(1)
                    this.select_air_port = index
                }
            },
            initMap() {
                const uluru = {lat: 39.90366038315295, lng: 116.40662542765467};
                this.markers = [];
                this.map = new google.maps.Map(document.getElementById("Map"), {
                    zoom: 4,
                    center: uluru,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    gestureHandling: "greedy",
                    disableDefaultUI: true,
                    styles: this.$store.state.map_styles,
                });
                this.desserts.map((location, i) => {
                    const marker = new google.maps.Marker({
                        position: {lat: parseFloat(location.lat), lng: parseFloat(location.lng)},
                        map: this.map,
                        labelContent: location.title,
                        labelClass: "labels",
                        labelStyle: {background: "#fff", padding: "5px"},
                        opacity: .5
                    });
                    this.markers.push(marker)


                    marker.setMap(this.map);
                    // var infowindow = new google.maps.InfoWindow({
                    //   // 信息窗口
                    //   content: location.title,
                    // });
                    // marker.addListener("mouseover", () => {
                    //   // hover 触发信息窗口
                    //   infowindow.open(this.map, marker);
                    // });
                    // marker.addListener("mouseout", () => {
                    //   infowindow.close(this.map, marker);
                    // });
                });

                this.click_airport_event(0)
            },
            toEdit(item) {
                this.$router.push({name: 'airport-edit', params: {id: item.id}})
            },
            open_preview_airport(item) {
                this.dialog = true
                this.$store.commit('set_air_port_id', item.id)
                this.dialog_airport_id = item.id
            },
            changePage(e) {
                this.search_info.pagesize = e.itemsPerPage;
                this.doGet(e.page);
            },
            change() {
                var hour = this.selectTime.startTime.split(":")[0];
                var minute = this.selectTime.startTime.split(":")[1] * 1 + 1;
                return `${hour}:${minute}`;
            },
            del(id) {
                this.$confirm({
                    title: this.$t('confirm_del'),
                    content: this.$t("airport.delete_airport"),
                    okText: this.$t('confirm'),
                    cancelText: this.$t('cancel')
                }).then((dialog) => {
                    del_info(id).then((res) => {
                        this.doGet();
                    });
                });
            },
            validate() {
                if (!this.dialog_airport_id) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t("airport.error_id"),
                        type: 'warning'
                    })
                }
                if (this.startTime && this.endTime) {
                    // const begin = this.selectTime.startDate + " " + this.selectTime.startTime
                    // const end = this.selectTime.endDate + " " + this.selectTime.endTime
                    // console.log(begin,end)

                    //         if (this.isAirport) {
                    //             this.$router.push({
                    //                 name: "aircraftDockingPreview",
                    //                 params: {
                    //                     start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                    //                     end_time: moment(this.end_time).format('YYYY-MM-DD HH:mm'),
                    //                     airport_id: this.dialog_airport_id
                    //                 }
                    //             })
                    //         } else {
                    //             this.$router.push({
                    //                 name: "protectionVehiclePreview",
                    //                 params: {
                    //                     start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                    //                     end_time: moment(this.end_time).format('YYYY-MM-DD HH:mm'),
                    //                     airport_id: this.dialog_airport_id
                    //                 }
                    //             })
                    //         }
                    //     } else {
                    //         this.$toast({
                    //             title: this.$t('error'),
                    //             message: this.$t("airport.select_time_range"),
                    //             type: 'waring'
                    //         })
                    //     }
                    // },
                    if (this.isAirport) {
                        this.$router.push({
                            name: "aircraftDockingPreview",
                            params: {
                                start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                                end_time: moment(this.endTime).format('YYYY-MM-DD HH:mm'),
                                airport_id: this.dialog_airport_id
                            }
                        })
                    } else {
                        this.$router.push({
                            name: "protectionVehiclePreview",
                            params: {
                                start_time: moment(this.startTime).format('YYYY-MM-DD HH:mm'),
                                end_time: moment(this.endTime).format('YYYY-MM-DD HH:mm'),
                                airport_id: this.dialog_airport_id
                            }
                        })
                    }
                } else {
                    this.$toast({
                        title: this.$t('error'),
                        message: this.$t("airport.select_time_range"),
                        type: 'waring'
                    })
                }
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex;
                this.search_info.pagesize = 999;
                this.loading = true;
                const closeLoading = this.$loading() // 打开加载动画
                get_list(this.search_info)
                    .then((res) => {
                        this.desserts = res.results;
                        this.list_count = res.count;
                        this.initMap()
                    })
                    .finally(() => {
                        this.loading = false;
                        closeLoading() // 关闭加载动画
                    });
            },
            changeTab() {
                this.isAirport = !this.isAirport
            },
            has_airport_id(id) {
                this.node_dialog = true;
                this.airport_id = id
            },
            submitNode() {
                this.$router.push({
                    name: 'airport-node',
                    params: {id: this.airport_id, selectNode: this.selectNode.value}
                })
            },


            openEdge(id) {
                this.edge_dialog = true
                this.airport_id = id
            },
            submitEdge() {
                this.$router.push({
                    name: 'airport-edge',
                    params: {id: this.airport_id, module_type: this.selectEdge.value}
                })
            }
        },
        mounted() {
            this.user_id=JSON.parse(localStorage.getItem('user_info')).user_id
            this.doGet()
        },
        watch: {
            '$store.state.map_styles'(v) {
                if (this.map) {
                    this.map.setOptions({
                        styles: v
                    })
                }
            },
            '$i18n.locale'(newValue) {
                if (this.$i18n.locale === 'zh') {

                } else if (this.$i18n.locale === 'en') {

                }
            }
        },
        computed: {
            headers() {
                return [
                    {
                        text: this.$t("airport.Name"),
                        align: "center",
                        value: "name",
                        sortable: false,
                    },
                    {
                        text: this.$t("airport.Area") + "(m²)",
                        value: "area",
                        align: "center",
                        sortable: false,
                    },
                    // {
                    //   text: this.$t("airport.Lat"),
                    //   value: "lat",
                    //   align: "center",
                    //   sortable: false,
                    // },
                    // {
                    //   text: this.$t("airport.Lng"),
                    //   value: "lng",
                    //   align: "center",
                    //   sortable: false,
                    // },
                    {
                        text: this.$t("airport.Apron_number"),
                        value: "apron_number",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: this.$t("airport.Runway_number"),
                        value: "runway_number",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: this.$t("airport.Place"),
                        value: "place",
                        align: "center",
                        sortable: false,
                    },
                    {text: this.$t("airport.Height"), value: "height", align: "center"},
                    {text: this.$t("airport.Weight"), value: "width", align: "center"},
                    {
                        text: this.$t("airport.Status"),
                        value: "status",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: this.$t("airport.Action"),
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ];
            },
            items() {
                return [
                    {
                        text: this.$t("airport.preview_airport")
                    },
                    {
                        text: this.$t("airport.preview_car")
                    }
                ]
            },
            node() {
                return [
                    {
                        text: this.$t("airport.airport_node"),
                        value: 0
                    },
                    {
                        text: this.$t("airport.security_node"),
                        value: 1
                    }
                ]
            },
            edge() {
                return [
                    {
                        text: this.$t("airport.airport_edge"),
                        value: 0
                    },
                ]
            },
        },
        components: {
            // Setting
            EditPassword,
            'custom-title': CustomTitle
        }
    };
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl';
    .airport-icon
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 120px;
        color var(--v-primary-base)
        opacity: .1

    .small-font
        font-size 22px !important

    .airport__item
        transition ease all .3s
        border-bottom 1px solid var(--v-text_color-base)

    .airport__item:hover
        transition ease all .3s

    /*box-shadow 0px 0px 20px #aaa*/
    #Map
        width: 100%;
        height: 105%;

    .menu
        width: 300px;
        height: 100px;
        background: var(--v-item_card-base);
        border-radius 3px
        position: relative;
        color #82868B
        user-select: none;
        cursor: pointer
        transition ease .3s all

        &__icon
            font-size 28px

        &:hover
            background rgba(91, 163, 235, .12)
            color #5BA3EB
            transition ease .1s all

    /*    position absolute*/
    /*    right 30px*/
    /*    font-size 62px*/
    /*    opacity .3*/
    .menu_box_hide
        height: 300px;
        width: 300px;
        background: var(--v-secondary-base);
        position: absolute;
        bottom: -180px;
        left: -180px;
        transform: rotate(45deg);
        cursor: pointer;
        color: #797979
        transition ease .3s all

        &:hover
            /*color #eee !important*/
            box-shadow: 0 0 3px var(--v-shadow_color-base);

    .hidden
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    .menu_box_show
        height: 400px;
        width: 1000px;
        background: var(--v-secondary-base);
        position: absolute;
        bottom: 50%;
        left: 50%;
        cursor: default;
        transform translate(-50%, 20%)
        /*border: 1px solid #666*/
        box-shadow 0 0 10px var(--v-shadow_color-base)
        border-radius 3px
        padding 30px
        padding-right 0px
        transition ease .3s all

    .search__box
        position: absolute;
        top: 0px;
        right: 20px;
        /*background: red;*/
        width: 300px;

    .right_airport_list
        height: calc(100vh - 180px);
        width: 300px;
        opacity: .3
        box-shadow 0 0 10px var(--v-shadow_color-base);
        box-sizing border-box
        background: var(--v-secondary-base);
        position: absolute;
        top: 84px;
        right: 20px;
        border-radius: 5px;
        overflow-y: scroll
        transition ease all .2s

        &:hover
            opacity 1
            transition .2s all ease

        &::-webkit-scrollbar
            display none

</style>
